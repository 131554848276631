import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IZZZBangboo } from '../../../common/model/graphql-types';
import { Accordion, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Range, getTrackBackground } from 'react-range';
import './zzz-bangboo.scss';

interface IProps {
  employee: IZZZBangboo;
  showLabel?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ZZZBangbooData: React.FC<IProps> = ({ employee, showLabel }) => {
  const image = employee.smallImage
    ? getImage(employee.smallImage.localFile.childImageSharp)
    : null;

  const [color, setColor] = useState('#979797');
  const [currentLevel, setCurrentLevel] = useState([1]);

  useEffect(() => {
    if (employee.rarity === 'B') {
      setColor('#038AEA');
    } else if (employee.rarity === 'A') {
      setColor('#B263E1');
    } else if (employee.rarity === 'S') {
      setColor('#D7BC57');
    }
  }, []);
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className={`avatar zzz rarity-${employee.rarity}`}>
              {image && (
                <GatsbyImage
                  className="disable-transition"
                  image={image}
                  alt={`${employee.name}`}
                />
              )}
            </div>
            <div className="information">
              <h2 className="name">{employee.name}</h2>
              <div className="details">
                <p>
                  Rarity:{' '}
                  <strong className={`rarity-${employee.rarity}`}>
                    {employee.rarity}-Rank
                  </strong>
                </p>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Tabs
              defaultActiveKey="skill-1"
              id={employee.slug}
              className="mb-3"
            >
              <Tab eventKey="skill-1" title="Skill 1 - Active">
                <div className="skills">
                  <div className="box">
                    <div className="skill-header">
                      <div className="skill-info">
                        <p className="skill-name">
                          {employee.skills.skill_a.name
                            ? employee.skills.skill_a.name
                            : 'Unknown name'}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`skill-description bangboo ${employee.skills.skill_a.element}`}
                      dangerouslySetInnerHTML={{
                        __html: employee.skills.skill_a.description
                      }}
                    />
                    <Accordion className="accordion-multis">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Multipliers</Accordion.Header>
                        <Accordion.Body>
                          <div className="stats-zzz skills">
                            <div className="stats-header">
                              <div className="level-changer">
                                <span className="current-level">
                                  Level.{' '}
                                  <span
                                    className="level"
                                    style={{ color: color }}
                                  >
                                    {currentLevel}
                                  </span>
                                </span>
                                <div className={`level-slider `}>
                                  <Range
                                    step={1}
                                    min={1}
                                    max={10}
                                    values={currentLevel}
                                    onChange={(values) =>
                                      setCurrentLevel(values)
                                    }
                                    renderTrack={({ props, children }) => (
                                      <div
                                        role="button"
                                        tabIndex={0}
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                          ...props.style,
                                          height: '36px',
                                          display: 'flex',
                                          width: '100%'
                                        }}
                                      >
                                        <div
                                          ref={props.ref}
                                          style={{
                                            height: '5px',
                                            width: '100%',
                                            borderRadius: '0px',
                                            background: getTrackBackground({
                                              values: currentLevel,
                                              colors: [color, '#484950'],
                                              min: 1,
                                              max: 10
                                            }),
                                            alignSelf: 'center'
                                          }}
                                        >
                                          {children}
                                        </div>
                                      </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                      <div
                                        {...props}
                                        style={{
                                          ...props.style,
                                          height: '20px',
                                          width: '20px',
                                          borderRadius: '0px',
                                          backgroundColor: '#FFF',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: '14px',
                                            width: '5px',
                                            backgroundColor: isDragged
                                              ? color
                                              : '#484950'
                                          }}
                                        />
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="multipliers inside">
                            {employee.slug === 'exploreboo' ||
                            employee.slug === 'butler' ||
                            employee.slug === 'knightboo' ||
                            employee.slug === 'booressure' ||
                            employee.slug === 'bangvolver' ||
                            employee.slug === 'avocaboo' ? (
                              <>
                                {employee.slug === 'knightboo' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">
                                        Energy Generation
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && <>0.5</>}
                                        {Number(currentLevel) === 2 && <>0.6</>}
                                        {Number(currentLevel) === 3 && <>0.7</>}
                                        {Number(currentLevel) === 4 && <>0.8</>}
                                        {Number(currentLevel) === 5 && <>0.9</>}
                                        {Number(currentLevel) === 6 && <>1</>}
                                        {Number(currentLevel) === 7 && <>1.1</>}
                                        {Number(currentLevel) === 8 && <>1.2</>}
                                        {Number(currentLevel) === 9 && <>1.3</>}
                                        {Number(currentLevel) === 10 && (
                                          <>1.4</>
                                        )}{' '}
                                        + Bangboo Level × 0.01
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">24s</div>
                                    </div>
                                  </>
                                )}
                                {employee.slug === 'exploreboo' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">HP Recovery</div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>8% HP</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>8.2% HP</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>8.4% HP</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>8.6% HP</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>8.8% HP</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>9.0% HP</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>9.2% HP</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>9.4% HP</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>9.6% HP</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>9.8% HP</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">HP Shield</div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>9% HP</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>9.3% HP</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>9.6% HP</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>9.9% HP</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>10.2% HP</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>10.5% HP</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>10.8% HP</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>11.1% HP</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>11.4% HP</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>11.7% HP</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Energy Gen</div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>0.2s</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>0.22s</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>0.24s</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>0.26s</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>0.28s</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>0.3s</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>0.32s</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>0.34s</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>0.36s</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>0.38s</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Buff Duration</div>
                                      <div className="value">12s</div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">16s</div>
                                    </div>
                                  </>
                                )}
                                {employee.slug === 'butler' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">
                                        Energy Generation
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>3 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>3.2 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>3.4 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>3.6 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>3.8 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>4 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>4.2 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>4.4 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>4.6 + Bangboo level x0.08</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>4.8 + Bangboo level x0.08</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">24s</div>
                                    </div>
                                  </>
                                )}
                                {employee.slug === 'avocaboo' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">HP Recovery</div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>10% HP</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>10.5% HP</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>11% HP</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>11.5% HP</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>12% HP</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>12.5% HP</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>13% HP</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>13.5% HP</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>14% HP</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>14.5% HP</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">20s</div>
                                    </div>
                                  </>
                                )}
                                {employee.slug === 'booressure' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">
                                        Energy Generation
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>2.5 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>2.6 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>2.7 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>2.8 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>2.9 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>3 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>3.1 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>3.2 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>3.3 + Bangboo level x0.05</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>3.4 + Bangboo level x0.05</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">26s</div>
                                    </div>
                                  </>
                                )}
                                {employee.slug === 'bangvolver' && (
                                  <>
                                    <div className="multi-single">
                                      <div className="name">
                                        Enhanced Shot DMG Multiplier
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>1152%</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>1267.2%</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>1382.4%</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>1497.6%</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>1612.8%</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>1728%</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>1843.2%</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>1958.4%</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>2073.6%</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>2188.8%</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">
                                        Enhanced Shot Daze Multiplier
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>421%</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>463.1%</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>505.2%</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>547.3%</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>589.4%</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>631.5%</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>673.6%</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>715.7%</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>757.8%</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>799.9%</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">
                                        Shot DMG Multiplier
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>576%</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>633.6%</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>691.2%</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>748.8%</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>806.4%</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>864%</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>921.6%</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>979.2%</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>1036.8%</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>1094.4%</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">
                                        Shot Daze Multiplier
                                      </div>
                                      <div className="value">
                                        {Number(currentLevel) === 1 && (
                                          <>211%</>
                                        )}
                                        {Number(currentLevel) === 2 && (
                                          <>232.1%</>
                                        )}
                                        {Number(currentLevel) === 3 && (
                                          <>252.2%</>
                                        )}
                                        {Number(currentLevel) === 4 && (
                                          <>273.3%</>
                                        )}
                                        {Number(currentLevel) === 5 && (
                                          <>294.4%</>
                                        )}
                                        {Number(currentLevel) === 6 && (
                                          <>315.5%</>
                                        )}
                                        {Number(currentLevel) === 7 && (
                                          <>336.6%</>
                                        )}
                                        {Number(currentLevel) === 8 && (
                                          <>357.7%</>
                                        )}
                                        {Number(currentLevel) === 9 && (
                                          <>379.8%</>
                                        )}
                                        {Number(currentLevel) === 10 && (
                                          <>400.8%</>
                                        )}
                                      </div>
                                    </div>
                                    <div className="multi-single">
                                      <div className="name">Cooldown</div>
                                      <div className="value">18s</div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="multi-single">
                                  <div className="name">DMG Multiplier</div>
                                  <div className="value">
                                    {(
                                      employee.skills.skill_a.base_multi +
                                      Number(currentLevel) *
                                        employee.skills.skill_a.rank_multi -
                                      employee.skills.skill_a.rank_multi
                                    ).toFixed(1)}
                                    %{employee.slug === 'luckyboo' && <>*140</>}
                                    {employee.slug === 'officer-cui' && <>*2</>}
                                  </div>
                                </div>
                                <div className="multi-single">
                                  <div className="name">Daze Multiplier</div>
                                  <div className="value">
                                    {(
                                      employee.skills.skill_a.daze +
                                      Number(currentLevel) *
                                        employee.skills.skill_a.daze_rank -
                                      employee.skills.skill_a.daze_rank
                                    ).toFixed(1)}
                                    %{employee.slug === 'luckyboo' && <>*140</>}
                                    {employee.slug === 'officer-cui' && <>*2</>}
                                  </div>
                                </div>
                                <div className="multi-single">
                                  <div className="name">Cooldown</div>
                                  <div className="value">
                                    {employee.skills.skill_a.cooldown}s
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="skill-2" title="Skill 2 - Passive">
                <div className="skills">
                  <div className="box">
                    <div className="skill-header">
                      <div className="skill-info">
                        <p className="skill-name">
                          {employee.skills.skill_b.name
                            ? employee.skills.skill_b.name
                            : 'Unknown name'}
                        </p>
                      </div>
                    </div>
                    <div className="stats-zzz">
                      <div className="stats-header">
                        <div className="level-changer">
                          <span className="current-level">
                            Level.{' '}
                            <span className="level" style={{ color: color }}>
                              {currentLevel}
                            </span>
                          </span>
                          <div className={`level-slider `}>
                            <Range
                              step={1}
                              min={1}
                              max={5}
                              values={currentLevel}
                              onChange={(values) => setCurrentLevel(values)}
                              renderTrack={({ props, children }) => (
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: '36px',
                                    display: 'flex',
                                    width: '100%'
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: '5px',
                                      width: '100%',
                                      borderRadius: '0px',
                                      background: getTrackBackground({
                                        values: currentLevel,
                                        colors: [color, '#484950'],
                                        min: 1,
                                        max: 5
                                      }),
                                      alignSelf: 'center'
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                              renderThumb={({ props, isDragged }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '20px',
                                    width: '20px',
                                    borderRadius: '0px',
                                    backgroundColor: '#FFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    style={{
                                      height: '14px',
                                      width: '5px',
                                      backgroundColor: isDragged
                                        ? color
                                        : '#484950'
                                    }}
                                  />
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {employee.skills.skill_b.description.map(
                      (upgrade, index) => {
                        return (
                          <div key={index}>
                            {Number(currentLevel) === 1 && index === 0 && (
                              <div
                                className={`skill-description bangboo ${employee.skills.skill_b.element}`}
                                dangerouslySetInnerHTML={{
                                  __html: upgrade.desc
                                }}
                              />
                            )}
                            {Number(currentLevel) === 2 && index === 1 && (
                              <div
                                className={`skill-description bangboo ${employee.skills.skill_b.element}`}
                                dangerouslySetInnerHTML={{
                                  __html: upgrade.desc
                                }}
                              />
                            )}
                            {Number(currentLevel) === 3 && index === 2 && (
                              <div
                                className={`skill-description bangboo ${employee.skills.skill_b.element}`}
                                dangerouslySetInnerHTML={{
                                  __html: upgrade.desc
                                }}
                              />
                            )}
                            {Number(currentLevel) === 4 && index === 3 && (
                              <div
                                className={`skill-description bangboo ${employee.skills.skill_b.element}`}
                                dangerouslySetInnerHTML={{
                                  __html: upgrade.desc
                                }}
                              />
                            )}
                            {Number(currentLevel) >= 5 && index >= 4 && (
                              <div
                                className={`skill-description bangboo ${employee.skills.skill_b.element}`}
                                dangerouslySetInnerHTML={{
                                  __html: upgrade.desc
                                }}
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Tab>
              {employee.skills.skill_c.name != '' && (
                <Tab eventKey="skill-3" title="Skill 3 - Chain Attack">
                  <div className="skills">
                    <div className="box">
                      <div className="skill-header">
                        <div className="skill-info">
                          <p className="skill-name">
                            {employee.skills.skill_c.name
                              ? employee.skills.skill_c.name
                              : 'Unknown name'}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`skill-description bangboo ${employee.skills.skill_c.element}`}
                        dangerouslySetInnerHTML={{
                          __html: employee.skills.skill_c.description
                        }}
                      />
                      <Accordion className="accordion-multis">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Multipliers</Accordion.Header>
                          <Accordion.Body>
                            <div className="stats-zzz skills">
                              <div className="stats-header">
                                <div className="level-changer">
                                  <span className="current-level">
                                    Level.{' '}
                                    <span
                                      className="level"
                                      style={{ color: color }}
                                    >
                                      {currentLevel}
                                    </span>
                                  </span>
                                  <div className={`level-slider `}>
                                    <Range
                                      step={1}
                                      min={1}
                                      max={10}
                                      values={currentLevel}
                                      onChange={(values) =>
                                        setCurrentLevel(values)
                                      }
                                      renderTrack={({ props, children }) => (
                                        <div
                                          role="button"
                                          tabIndex={0}
                                          onMouseDown={props.onMouseDown}
                                          onTouchStart={props.onTouchStart}
                                          style={{
                                            ...props.style,
                                            height: '36px',
                                            display: 'flex',
                                            width: '100%'
                                          }}
                                        >
                                          <div
                                            ref={props.ref}
                                            style={{
                                              height: '5px',
                                              width: '100%',
                                              borderRadius: '0px',
                                              background: getTrackBackground({
                                                values: currentLevel,
                                                colors: [color, '#484950'],
                                                min: 1,
                                                max: 10
                                              }),
                                              alignSelf: 'center'
                                            }}
                                          >
                                            {children}
                                          </div>
                                        </div>
                                      )}
                                      renderThumb={({ props, isDragged }) => (
                                        <div
                                          {...props}
                                          style={{
                                            ...props.style,
                                            height: '20px',
                                            width: '20px',
                                            borderRadius: '0px',
                                            backgroundColor: '#FFF',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: '14px',
                                              width: '5px',
                                              backgroundColor: isDragged
                                                ? color
                                                : '#484950'
                                            }}
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="multipliers inside">
                              <div className="multi-single">
                                <div className="name">DMG Multiplier</div>
                                <div className="value">
                                  {(
                                    employee.skills.skill_c.base_multi +
                                    Number(currentLevel) *
                                      employee.skills.skill_c.rank_multi -
                                    employee.skills.skill_c.rank_multi
                                  ).toFixed(1)}
                                  %
                                </div>
                              </div>
                              <div className="multi-single">
                                <div className="name">Daze Multiplier</div>
                                <div className="value">
                                  {(
                                    employee.skills.skill_c.daze +
                                    Number(currentLevel) *
                                      employee.skills.skill_c.daze_rank -
                                    employee.skills.skill_c.daze_rank
                                  ).toFixed(1)}
                                  %
                                </div>
                              </div>
                              {employee.slug === 'devilboo' && (
                                <>
                                  <div className="multi-single">
                                    <div className="name">
                                      Coordinated Attack DMG Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && <>37%*2</>}
                                      {Number(currentLevel) === 2 && (
                                        <>40.7%*2</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>44.4%*2</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>48.1%*2</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>51.8%*2</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>55.5%*2</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>59.2%*2</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>62.9%*2</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>66.6%*2</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>70.3%*2</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Coordinated Attack Daze Multiplier
                                    </div>
                                    <div className="value">
                                      {Number(currentLevel) === 1 && (
                                        <>5.8%*2</>
                                      )}
                                      {Number(currentLevel) === 2 && (
                                        <>6.2%*2</>
                                      )}
                                      {Number(currentLevel) === 3 && (
                                        <>6.7%*2</>
                                      )}
                                      {Number(currentLevel) === 4 && (
                                        <>7.2%*2</>
                                      )}
                                      {Number(currentLevel) === 5 && (
                                        <>7.7%*2</>
                                      )}
                                      {Number(currentLevel) === 6 && (
                                        <>8.2%*2</>
                                      )}
                                      {Number(currentLevel) === 7 && (
                                        <>8.7%*2</>
                                      )}
                                      {Number(currentLevel) === 8 && (
                                        <>9.2%*2</>
                                      )}
                                      {Number(currentLevel) === 9 && (
                                        <>9.7%*2</>
                                      )}
                                      {Number(currentLevel) === 10 && (
                                        <>10.2%*2</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Coordination State Duration
                                    </div>
                                    <div className="value">30s</div>
                                  </div>
                                  <div className="multi-single">
                                    <div className="name">
                                      Additional Coordinated Attacks
                                    </div>
                                    <div className="value">10 Activations</div>
                                  </div>
                                </>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </Tab>
              )}
              <Tab eventKey="stats" title="Stats">
                <div className="stats-zzz">
                  <Row xs={1} xl={1} xxl={2} className="info-list stats">
                    <Col>
                      <div className="stat-box">
                        <div className="info-list-row">
                          <div className="category">HP</div>
                          <div className={`details`}>
                            {employee.statsNew.hp}
                          </div>
                        </div>
                        <div className="info-list-row">
                          <div className="category">ATK</div>
                          <div className={`details`}>
                            {employee.statsNew.atk}
                          </div>
                        </div>
                        <div className="info-list-row">
                          <div className="category">DEF</div>
                          <div className={`details`}>
                            {employee.statsNew.def}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="stat-box">
                        <div className="info-list-row">
                          <div className="category">CRIT Rate</div>
                          <div className={`details`}>50%</div>
                        </div>
                        <div className="info-list-row">
                          <div className="category">CRIT DMG</div>
                          <div className={`details`}>100%</div>
                        </div>
                        <div className="info-list-row">
                          <div className="category with-help">Impact </div>
                          <div className={`details`}>
                            {employee.statsNew.impact}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
